import classNames from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import {type ValidateResult, useForm} from 'react-hook-form';

import {type BUTTON_STYLES, Button} from '@/components/base/button/Button';
import {Input} from '@/components/base/input/Input';
import type {INPUT_STYLES} from '@/components/base/input/types';
import {RequiredInput} from '@/components/base/required-input/RequiredInput';
import type {TEXTAREA_STYLES} from '@/components/base/textarea/Textarea';
import {Additions} from '@/components/common/forms/additions/Additions';
import {AgreementBlock} from '@/components/common/forms/agreement-block/AgreementBlock';
import {GoalsModal} from '@/components/common/forms/goals-modal/GoalsModal';
import type {MESSENGERS} from '@/components/common/forms/messengers-block/const';
import {MessengerBlock} from '@/components/common/forms/messengers-block/MessengerBlock';
import {useHandleSubmitData} from '@/components/common/forms/messengers-block/use-handle-submit-data';
import {getRecaptchaDisclaimer} from '@/components/common/forms/request-form/helpers';
import styles from '@/components/common/forms/request-form/RequestForm.module.css';
import {PHONE_MASK} from '@/constants';
import {withRecaptcha} from '@/hocs/with-recaptcha';
import {MeStore} from '@/mobx/business-models/me/MeModel';
import type {RequestPostData} from '@/services/api/interfaces/request';
import {setGAClientId} from '@/utilites/analytics';
import {getFormError} from '@/utilites/get-form-error/get-form-error';
import {validatePhone} from '@/utilites/validate-phone/validate-phone';

interface RequestFormProps {
	onSubmit?: (data: RequestPostData) => void;
	onClick?: () => void;
	onClose?: () => void;
	autoFocusFirstInput?: boolean;
	buttonText?: string;
	buttonClassName?: string;
	inputStyle?: INPUT_STYLES;
	textareaStyle?: TEXTAREA_STYLES;
	buttonStyle?: BUTTON_STYLES;
	controlClassName?: string;
	contactsClassName?: string;
	buttonWrapperClassName?: string;
	className?: string;
	commentText?: string;
	disclaimer?: JSX.Element | string;
	isModal?: boolean;
	noPhoneMask?: boolean;
}

const phoneId = 'phone';
const emailId = 'email';
const nameId = 'name';
const companyId = 'company';
const messageId = 'message';

// eslint-disable-next-line max-lines-per-function
function PureRequestForm({
	onSubmit,
	onClick,
	autoFocusFirstInput,
	buttonText,
	buttonClassName,
	buttonStyle,
	inputStyle,
	controlClassName,
	contactsClassName,
	buttonWrapperClassName,
	className,
	disclaimer = getRecaptchaDisclaimer(),
	isModal = false,
	noPhoneMask = false,
}: RequestFormProps) {
	const {
		register,
		handleSubmit,
		reset,
		formState: {errors, isSubmitSuccessful},
	} = useForm();
	const [isAgreement, setIsAgreement] = useState(false);
	const {resetMessengers, messengerValues, setMessengers, messengers} = useHandleSubmitData<MESSENGERS>();

	const {phone, email} = MeStore;
	useEffect(() => {
		reset({
			[phoneId]: phone,
			[emailId]: email,
			[nameId]: '',
			[companyId]: '',
		});
		resetMessengers();
	}, [email, isSubmitSuccessful, phone, reset, resetMessengers]);

	useEffect(() => {
		setGAClientId();
	}, []);

	const handleFormSubmit = useCallback(
		(data) => {
			if (messengerValues) {
				onSubmit({...data, [messageId]: messengerValues});
				return;
			}
			onSubmit(data);
		},
		[messengerValues, onSubmit],
	);

	return (
		<form onSubmit={handleSubmit(handleFormSubmit)} className={classNames(styles.form, className)}>
			<div className={classNames(styles.contacts, contactsClassName)}>
				<RequiredInput
					id={phoneId}
					autoFocus={autoFocusFirstInput}
					autocomplete="tel"
					type="tel"
					{...(noPhoneMask ? {} : {mask: PHONE_MASK})}
					placeholder="Телефон"
					controlClassName={classNames(styles.control, [styles.contactsControl, controlClassName])}
					inputStyle={inputStyle}
					registerProps={register(phoneId, {
						required: true,
						validate: (value): ValidateResult =>
							validatePhone(value) ? true : 'Некорректный номер телефона',
					})}
					error={getFormError(errors[phoneId])}
					className={styles.blockMargin}
				/>
				<Input
					id={emailId}
					autocomplete="email"
					placeholder="Электронная почта"
					className={classNames(styles.control, [
						styles.email,
						styles.contactsControl,
						controlClassName,
					])}
					inputStyle={inputStyle}
					{...register(emailId, {
						required: false,
					})}
				/>
			</div>
			<RequiredInput
				id={nameId}
				autocomplete="name"
				placeholder="Ваше имя"
				registerProps={register(nameId, {required: true})}
				controlClassName={classNames(styles.control, controlClassName)}
				inputStyle={inputStyle}
				error={getFormError(errors[nameId])}
				className={styles.blockMargin}
			/>
			<Input
				id={companyId}
				autocomplete="organization"
				placeholder="Компания"
				{...register(companyId, {required: false})}
				className={classNames(styles.control, controlClassName, styles.blockMargin)}
				inputStyle={inputStyle}
			/>
			{isModal && <GoalsModal className={classNames(styles.goals, styles.blockMargin)} />}
			<MessengerBlock currentValues={messengers} onClick={setMessengers} className={styles.messages} />
			<AgreementBlock value={isAgreement} onChange={() => setIsAgreement((v) => !v)} />
			{disclaimer && <Additions disclaimer={disclaimer} />}
			<div className={classNames(styles.buttonWrapper, buttonWrapperClassName)}>
				<Button
					disabled={!isAgreement}
					type="submit"
					className={classNames(styles.button, buttonClassName)}
					style={buttonStyle}
					onClick={onClick}
				>
					{buttonText ?? 'Задать вопрос'}
				</Button>
			</div>
		</form>
	);
}

export const RequestForm = withRecaptcha(PureRequestForm);
